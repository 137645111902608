  import {
      FC,
      useState,
      useEffect,
      createContext,
      useContext,
      useRef,
      Dispatch,
      SetStateAction,
    } from 'react'
  import { useParams, useNavigate } from 'react-router-dom'
  import { WithChildren } from '../../../../_metronic/helpers'
  import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
  import {  EnterpriseModel, 
            CategoryModel, 
            SerialModel, 
            ProductModel, 
            ClientModel, 
            InvoiceItem, 
            PaymentModel, 
            PermissionModel,
            SuscriptionModel,
            ReportModel
  } from './_models'
  import { getEnterprises, getEnterpriseOne, getSuscription, getReport} from './_requests'
  import { useAuth } from '../../auth'
  import { AccountingModel } from '../components/accounting/AccountingModel'

  const currentYearServer = new Date().getFullYear();
  type EnterpriseContextProps = {
    enterprises: EnterpriseModel[];
    saveEnterprises: Dispatch<SetStateAction<EnterpriseModel[]>>;
    currentEnterprise: EnterpriseModel | undefined;
    setCurrentEnterprise: Dispatch<SetStateAction<EnterpriseModel | undefined>>;
    currentYear: number | undefined;
    setCurrentYear: Dispatch<SetStateAction<number >>;
    currentAccounting: AccountingModel | undefined;
    setCurrentAccounting: Dispatch<SetStateAction<AccountingModel | undefined>>;
    showAsideMenuEnterprise: boolean;
    setShowAsideMenuEnterprise: Dispatch<SetStateAction<boolean>>;
    currentCategory: CategoryModel | undefined;
    setCurrentCategory: Dispatch<SetStateAction<CategoryModel | undefined>>;
    currentSerial: SerialModel | undefined;
    setCurrentSerial: Dispatch<SetStateAction<SerialModel | undefined>>;
    currentProduct: ProductModel | undefined;
    setCurrentProduct: Dispatch<SetStateAction<ProductModel | undefined>>;
    currentClient: ClientModel | undefined;
    setCurrentClient: Dispatch<SetStateAction<ClientModel | undefined>>;
    invoiceItems: InvoiceItem[];
    saveInvoiceItems: Dispatch<SetStateAction<InvoiceItem[]>>;
    currentPayment: PaymentModel | undefined;
    setCurrentPayment: Dispatch<SetStateAction<PaymentModel | undefined>>;
    currentPermissions: string | undefined;
    setCurrentPermissions: Dispatch<SetStateAction<string>>;
    permissions: PermissionModel[];
    setPermissions: Dispatch<SetStateAction<PermissionModel[]>>;
    currentSuscription: SuscriptionModel | undefined;
    setCurrentSuscription: Dispatch<SetStateAction<SuscriptionModel | undefined>>;
    currentReport: ReportModel | undefined;
    setCurrentReport: Dispatch<SetStateAction<ReportModel | undefined>>;
  };

  const initEnterpriseContextPropsState: EnterpriseContextProps = {
    enterprises: [],
    saveEnterprises: () => {},
    currentEnterprise: undefined,
    setCurrentEnterprise: () => {},
    currentYear: undefined,
    setCurrentYear: () => {},
    currentAccounting: undefined,
    setCurrentAccounting: () => {},
    showAsideMenuEnterprise: false, //
    setShowAsideMenuEnterprise: () => {},
    currentCategory: undefined,
    setCurrentCategory: () => {},
    currentSerial: undefined,
    setCurrentSerial: () => {},
    currentProduct: undefined,
    setCurrentProduct: () => {},
    currentClient: undefined,
    setCurrentClient: () => {},
    invoiceItems: [],
    saveInvoiceItems: () => {},
    currentPayment: undefined,
    setCurrentPayment: () => {},
    currentPermissions: undefined,
    setCurrentPermissions: () => {},
    permissions: [],
    setPermissions: () => {},
    currentSuscription: undefined,
    setCurrentSuscription: () => {},
    currentReport: undefined,
    setCurrentReport: () => {},
  };

  const EnterpriseContext = createContext(initEnterpriseContextPropsState);

  const UseEnterprise = () => {
      return useContext(EnterpriseContext);
  };

  const EnterprisesProvider: FC<WithChildren> = ({ children }) => {
    //const year = parseInt( Date.prototype.getFullYear().toString())
    const [enterprises, saveEnterprises] = useState<EnterpriseModel[]>([]);
    const [currentEnterprise, setCurrentEnterprise] = useState<EnterpriseModel | undefined>(undefined);
    const [currentAccounting, setCurrentAccounting] = useState<AccountingModel | undefined>(undefined);
    const [currentYear, setCurrentYear] = useState<number>(currentYearServer);
    const [showAsideMenuEnterprise, setShowAsideMenuEnterprise] = useState<boolean>(false);
    const [currentCategory, setCurrentCategory] = useState<CategoryModel | undefined>(undefined);
    const [currentSerial, setCurrentSerial] = useState<SerialModel | undefined>(undefined);
    const [currentProduct, setCurrentProduct] = useState<ProductModel | undefined>(undefined);
    const [currentClient, setCurrentClient] = useState<ClientModel | undefined>(undefined);
    const [currentPayment, setCurrentPayment] = useState<PaymentModel | undefined>(undefined);
    const [invoiceItems, saveInvoiceItems] = useState<InvoiceItem[]>([]);
    const [currentPermissions, setCurrentPermissions] = useState<string>('');
    const [permissions, setPermissions] = useState<PermissionModel[]>([]);
    const [currentSuscription, setCurrentSuscription] = useState<SuscriptionModel | undefined>(undefined);
    const [currentReport, setCurrentReport] = useState<ReportModel | undefined>(undefined);
    // const { auth } = useAuth()
    // const didRequest = useRef(false)
    // const [showSplashScreen, setShowSplashScreen] = useState(true)

    return (
      <EnterpriseContext.Provider
        value={{ 
          enterprises, 
          saveEnterprises, 
          currentEnterprise, 
          setCurrentEnterprise, 
          currentYear, 
          setCurrentYear, 
          currentAccounting, 
          setCurrentAccounting,
          showAsideMenuEnterprise,
          setShowAsideMenuEnterprise,
          currentCategory,
          setCurrentCategory,
          currentSerial,
          setCurrentSerial,
          currentProduct,
          setCurrentProduct,
          currentClient,
          setCurrentClient,
          invoiceItems,
          saveInvoiceItems,
          currentPayment,
          setCurrentPayment,
          currentPermissions,
          setCurrentPermissions,
          permissions,
          setPermissions,
          currentSuscription,
          setCurrentSuscription,
          currentReport,
          setCurrentReport
        }}
      >
        {children}
      </EnterpriseContext.Provider>
    );
  };

  const EnterprisesInit: FC<WithChildren> = ({children}) => {
    const { auth } = useAuth()
    const { saveEnterprises} = UseEnterprise()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    const { showAsideMenuEnterprise, setShowAsideMenuEnterprise} = UseEnterprise()
    if (showAsideMenuEnterprise === true) setShowAsideMenuEnterprise(false)

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
      const requestEnterprises = async (apiToken: string) => {
        try {
          if (!didRequest.current) {
            const {data} = await getEnterprises(apiToken)
            if (data) {
              saveEnterprises(data)
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          setShowSplashScreen(false)
        }
  
        return () => (didRequest.current = true)
      }

      if (auth && auth.api_token) requestEnterprises(auth.api_token)
      
      // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
  }

  const SuscriptionInit: FC<WithChildren> = ({children}) => {
    const { auth } = useAuth()
    const { setCurrentSuscription} = UseEnterprise()

    const [showSplashScreen, setShowSplashScreen] = useState(true)
    const { showAsideMenuEnterprise, setShowAsideMenuEnterprise} = UseEnterprise()
    if (showAsideMenuEnterprise === true) setShowAsideMenuEnterprise(false)

    useEffect(() => {
      const requestSuscription = async (apiToken: string) => {
        try {
          const {data} = await getSuscription(apiToken)            
          if (data)   setCurrentSuscription(data)
          else setCurrentSuscription(undefined)
        } catch (error) {
          console.error(error)
        } finally {
          setShowSplashScreen(false)
        }
      }

      if (auth && auth.api_token) requestSuscription(auth.api_token )
      // eslint-disable-next-line
    }, [])
    
    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
  }

  const EnterpriseOne: FC<WithChildren> = ({children}) => {
    const { auth } = useAuth()
    const { setCurrentEnterprise, setCurrentYear, setCurrentAccounting, setCurrentPermissions, setPermissions, currentEnterprise} = UseEnterprise()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    const { enterpriseId , year} = useParams();
    const navigate = useNavigate()
    const { showAsideMenuEnterprise, setShowAsideMenuEnterprise} = UseEnterprise()

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
      const requestEnterprises = async (apiToken: string, id: string, year: number) => {
        try {
          if (!didRequest.current && id) {
            const {data} = await getEnterpriseOne(apiToken, id)
            if (data && auth) {
              const accountingYear =  data?.accountings.find((item: AccountingModel) => item.year === year);
              setCurrentEnterprise(data)
              setCurrentYear(year)
              setCurrentAccounting(accountingYear)
              setPermissions(data.relations)

              const currentRelation = data.relations.find((item: PermissionModel) => item.id === auth.id)
              setCurrentPermissions(currentRelation.permissions)

              if (showAsideMenuEnterprise === false) setShowAsideMenuEnterprise(true)
            }
          }
          
        } catch (error) {
          console.error(error)
        } finally {
          setShowSplashScreen(false)
        }
  
        return () => (didRequest.current = true)
      }

      let currentYear = currentYearServer
      if (year) currentYear = parseInt(year)

      if (auth && auth.api_token && enterpriseId && currentYear) requestEnterprises(auth.api_token, enterpriseId, currentYear)
      
      // eslint-disable-next-line
    }, [])
    
    if(currentEnterprise?.active === false) {
      navigate("/dashboard")
    } 
    
    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
  }

  const ReportInit: FC<WithChildren> = ({children}) => {
    const { auth } = useAuth()
    const { setCurrentReport } = UseEnterprise()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
      const requestEnterprises = async (apiToken: string) => {
        try {
          if (!didRequest.current ) {
            const {data} = await getReport(apiToken)
            if (data && auth) {
              setCurrentReport(data)
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          setShowSplashScreen(false)
        }
  
        return () => (didRequest.current = true)
      }

      if (auth && auth.api_token ) requestEnterprises(auth.api_token)
      
      // eslint-disable-next-line
    }, [])
        
    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
  }
  
  export { EnterprisesProvider, UseEnterprise, EnterprisesInit, EnterpriseOne, SuscriptionInit , ReportInit};